import { Box, Typography, Stack, useTheme, Grid } from '@mui/material';
import { bgGradient } from '@/app/theme/css';
import Iconify from '@/app/components/iconify';
import InViewAnimationComponent from '@/app/components/in-view-animation-container';

const featuresData = [
  {
    title: 'Fast Streaming',
    description:
      'Experience lightning-fast streaming with minimal buffering for uninterrupted entertainment',
    icon: 'uil:tachometer-fast',
  },
  {
    title: 'Device Compatibility',
    description:
      'Stream seamlessly across all your devices, including smartphones, tablets, smart TVs, and more',
    icon: 'ph:devices-duotone',
  },
  {
    title: 'Affordable Pricing',
    description:
      'Get top-tier performance at a lower cost with our budget-friendly activation plan, designed to give you more value for less',
    icon: 'solar:dollar-linear',
  },
  {
    title: 'Simple UI',
    description:
      'Navigate effortlessly with our user-friendly interface designed for a smooth and intuitive experience',
    icon: 'fluent:table-simple-16-regular',
  },
  {
    title: 'Multilingual',
    description:
      'Access content in your preferred language with support for multiple languages, making it easy for everyone to enjoy',
    icon: 'lucide:languages',
  },
  {
    title: '24/7 Support',
    description:
      'Enjoy round-the-clock assistance with our dedicated support team, available anytime to help with your needs',
    icon: 'streamline:call-center-support-service',
  },
];

export default function OurFeatures() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mx: 'auto',
        width: {
          md: '100%',
          lg: '1200px',
        },
        pt: 15,
        pb: 15,
      }}
    >
      <Stack gap={6}>
        <Stack alignItems="center" gap={2} paddingX={3}>
          <Typography variant="h3">Why Choose Smart Max IPTV</Typography>
          <Typography
            variant="h6"
            sx={{
              maxWidth: 600,
              textAlign: 'center',
            }}
            color={theme.palette.text.secondary}
          >
            Experience seamless streaming with unmatched performance, device compatibility, and 24/7
            support—all at an affordable price
          </Typography>
        </Stack>
        <Stack paddingX={3}>
          <Grid container spacing={3}>
            {featuresData.map((data, index) => (
              <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                <InViewAnimationComponent>
                  <Stack
                    sx={{
                      bgcolor: theme.palette.background.neutral,
                      borderRadius: '10px',
                      boxShadow: theme.customShadows.dialog,
                      cursor: 'pointer',
                    }}
                    p={3}
                    gap={2}
                    alignItems="center"
                    textAlign="center"
                  >
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        ...bgGradient({
                          startColor: theme.palette.primary.main,
                          endColor: theme.palette.primary.dark,
                        }),
                        borderRadius: 25,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify icon={data.icon} width={30} color={theme.palette.common.black} />
                    </Box>
                    <Typography variant="subtitle1">{data.title}</Typography>
                    <Stack sx={{ height: 60, justifyContent: 'center' }}>
                      <Typography variant="body2">{data.description}</Typography>
                    </Stack>
                  </Stack>
                </InViewAnimationComponent>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
}
